<template>
  <v-container fluid>
    <h2 class="h2 text-left">{{ $t("roles.pickPermissionsTitle") }}</h2>
    <p
      class="subtitle-1 text-left"
      v-html="$t('roles.pickPermissionsDesc')"
    ></p>

    <v-row>
      <v-btn
        rounded
        color="primary"
        small
        class="ma-3"
        v-for="defaultRoles in defaultsWithPermissions"
        :key="defaultRoles.userRoleId"
        @click="() => addPermissionsFromDefault(defaultRoles.permissions)"
      >
        {{ defaultRoles.name }}
      </v-btn>

      <v-btn
        rounded
        small
        class="ma-3 white--text"
        color="grey"
        @click="clearToggled"
      >
        {{ $t("common.clear") }}
      </v-btn>
    </v-row>

    <v-row>
      <v-expansion-panels v-model="panels" inset multiple>
        <v-expansion-panel v-for="(_, i) in lPermissions" :key="i">
          <v-expansion-panel-header class="white--text" color="grey darken-2">{{
            $t("roles.layerTypes." + i)
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col
                  lg="3"
                  md="4"
                  sm="6"
                  xs="6"
                  v-for="permission in lPermissions[i]"
                  :key="permission.userPermissionId"
                  style="overflow: hidden"
                >
                  <v-btn
                    depressed
                    rounded
                    x-small
                    :class="
                      isActive(permission.userPermissionId)
                        ? 'success white--text'
                        : isRecommended(permission.userPermissionId)
                        ? 'yellow darken-3'
                        : 'grey white--text'
                    "
                    @click="toggle(permission.userPermissionId)"
                  >
                    {{ permission.name }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row
      v-if="missingRecommended"
      class="mt-4 d-flex align-center justify-center"
      style="width: 100%"
    >
      <v-card
        style="width: 60%; background-color: #f57f1736 !important"
        @click="addAllRecommended"
      >
        <v-card-text>
          <h6 class="text-h6 yellow--text text--darken-4">
            {{ $t("roles.missingRecommendedTitle") }}
          </h6>
          <v-btn text color="yellow darken-4">{{
            $t("roles.missingRecommendedButton")
          }}</v-btn>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "PermissionPicker",

  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      panels: [0, 0],
      toggled: [],
    };
  },

  computed: {
    ...mapState("roles", ["permissions", "recommended"]),
    ...mapGetters("roles", ["defaultsWithPermissions"]),

    lPermissions() {
      return this.permissions;
    },

    missingRecommended() {
      for (var r of this.recommended)
        if (!this.toggled.includes(r)) {
          return true;
        }

      return false;
    },
  },

  methods: {
    ...mapActions("roles", ["getRecommendedPermissions", "getPermissions"]),

    isRecommended(id) {
      return this.recommended.includes(id);
    },

    toggle(id) {
      var res = this.toggled.findIndex((e) => e == id);
      if (res == -1) {
        this.toggled.push(id);
      } else {
        this.toggled.splice(res, 1);
      }

      this.getRecommendedPermissions(this.toggled);
      this.$emit("input", this.toggled);
    },

    isActive(id) {
      return this.toggled.includes(id);
    },

    addPermissionsFromDefault(rolePermissions) {
      for (var p of rolePermissions) {
        if (!this.toggled.includes(p.userPermissionId))
          this.toggled.push(p.userPermissionId);
      }

      this.$emit("input", this.toggled);
      this.getRecommendedPermissions(this.toggled);
    },

    clearToggled() {
      this.toggled = [];
      this.getRecommendedPermissions(this.toggled);
      this.$emit("input", []);
    },

    addAllRecommended() {
      let missing = [];
      for (var r of this.recommended)
        if (!this.toggled.includes(r)) this.toggled.push(r);

      if (missing.length != 0) this.toggled = this.toggled.concat(missing);
    },
  },

  async created() {
    if (this.value != null && this.value.length > 0) this.toggled = this.value;

    this.getRecommendedPermissions(this.toggled);
    await this.getPermissions();
  },
};
</script>