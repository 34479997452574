<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-card-text>
        <v-text-field
          :label="$t('roles.fields.name')"
          v-model="role.name"
          prepend-icon="mdi-rename-box"
          :rules="required"
        ></v-text-field>
      </v-card-text>

      <!-- Create the custom permission picker -->
      <v-card-text>
        <permission-picker v-model="role.permissionGuids" />
      </v-card-text>

      <v-card-text>
        <v-btn @click="submit">Submit</v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PermissionPicker from "./PermissionPicker.vue";

export default {
  name: "RoleCreate",

  components: {
    PermissionPicker,
  },

  data() {
    return {
      valid: false,
      role: {
        name: "",
        permissionsGuids: [],
      },
      required: [(v) => !!v || this.$t("dashboard.nameReq")],
    };
  },

  computed: {
    ...mapState("roles", { stateRole: "role" }),
  },

  methods: {
    ...mapActions("roles", ["updateRole", "getRoleById"]),

    async submit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      await this.updateRole(this.role);
    },
  },

  created() {
    if (this.$route.params.id) {
      this.getRoleById(this.$route.params.id);
      if (this.stateRole.userRoleId == null) {
        this.$router.push("/roles");
        return;
      }

      this.role.userRoleId = this.stateRole.userRoleId;
      this.role.name = this.stateRole.name;
      this.role.permissionGuids = this.stateRole.permissions.map(
        (e) => e.userPermissionId
      );
    }
  },
};
</script>